@font-face {
    font-family: OpenSans;
    src: url(../../fonts/OpenSans-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: OpenSansBold;
    src: url(../../fonts/OpenSans-Bold.ttf);
    font-display: swap;
}
// arabic
@font-face {
    font-family: Janna-LT-Regular;
    src: url(../../fonts/ArbFONTS-ArbFONTS-Janna-LT-Regular.ttf);
    font-display: swap;
}
@font-face {
    font-family: Janna-LT-Bold;
    src: url(../../fonts/ArbFONTS-ArbFONTS-Janna-LT-Bold.ttf);
    font-display: swap;
}

//-*----------  START FONTS BASE  ----------*/
$primaryFont: "OpenSans", sans-serif;
$primaryFontBoldt: "OpenSansBold", sans-serif;
$base-fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$Arabic-Font-Regular: "Janna-LT-Regular", sans-serif;
$Arabic-Font-Bold: "Janna-LT-Bold", sans-serif;

//-*----------  END FONTS BASE  ----------*/

//%-*=====  End of GLOBAL STYLE SECTION  ======*-//
$main-font: $primaryFont;
$main-font-bold: $primaryFontBoldt;
$primary-color: #50a433;

$secondary-color: #707070;
$third-text-color: #312783;
$forth-text-color: #454545;
$third-color: #1b72a4;
// $primary-text-color: #2d2d2d;
// $primary-background-color: white;
// $secondary-background-color: #1d252c;

$default-font-size: 1rem;
