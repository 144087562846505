@import "../../blocks/steps";

.cart-header {
    justify-content: space-between;
}

.cart .form-box {
    margin-bottom: 50px;

    h4 {
        color: $secondary-color;
        font-weight: bold;
        margin-bottom: 20px;
    }
}

.horizontal-product {
    padding: 10px;
    border: 2px solid #e0e0e0;
    border-radius: 20px;
    column-gap: 8px;
    align-items: flex-start;
    margin-bottom: 20px;
    img {
        width: 25%;
        max-height: 170px;
    }
    p {
        color: $secondary-color;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
.horizontal-product__contents {
    width: 100%;
}
.horizontal-product__contents__header {
    margin-bottom: 10px;
    align-items: flex-start;

    justify-content: space-between;
    a,
    h6 {
        color: $primary-color !important;
        display: block;
        font-weight: bold;
    }
    button {
        border-radius: 50%;
        border: 1px solid #ff485a;
        height: 35px;
        width: 35px;
        background-color: #fff;
        color: #ff485a;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.horizontal-product__contents__footer {
    justify-content: space-between;
    margin-top: 12px;
    p {
        color: $third-text-color;
        font-weight: bolder;
        margin-bottom: 0;
        font-size: 20px;
    }
    div {
        border: 1px solid $primary-color;
        border-radius: 50px;
        padding: 2px 10px;
        input {
            width: 40px;
            text-align: center;
            font-weight: bold;
            color: #000;
        }
        i {
            font-size: 13px;
            color: $primary-color;
        }
        span {
            cursor: pointer;
            color: $primary-color;
        }
    }
}
.invoice {
    ul {
        padding: 30px;
        background-color: #edf6ea;
        border-radius: 20px;
        margin-bottom: 20px;
        li {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 15px;
            span {
                &:first-child {
                    font-size: 20px;
                }
                &:last-child {
                    font-size: 22px;
                    // font-family: $main-font-bold;
                    margin-right: 80px;
                }
            }
        }
        li {
            &:first-child,
            &:last-child {
                span {
                    &:first-child {
                        font-size: 25px;
                    }
                }
            }
        }
        .title-list {
            margin-bottom: 20px;
        }
        .total,
        .title-list {
            span {
                color: $primary-color;
                font-size: 25px;
                // font-family: $main-font-bold;
                text-transform: capitalize;
            }
        }
        .tax {
            // margin-top: 40px;
        }
        .total {
            margin-top: 40px;
            padding-bottom: 10px;
        }
    }
    .invoice-location-insert {
        border-top: 1px solid $primary-color;
        padding-top: 15px;
        .location__header {
            margin-bottom: 5px;

            h6 {
                margin-top: 5px;
            }
        }
        p {
            color: $secondary-color;
            font-size: 14px;
            margin-bottom: 5px;
        }
        .location-footer {
            padding-bottom: 15px;
        }
    }
    .submit-button {
        width: 100%;
    }
    .invoice-location {
        padding: 20px 30px;
        background-color: #edf6ea;
        border-radius: 20px;
        margin-bottom: 30px;
        span,
        p {
            color: #9f9f9f;
            font-size: 14px;
            margin-bottom: 5px;
        }
        .location__header h6 {
            max-width: 100%;
        }
    }
}
.payment {
    padding: 15px;
    border: 2px solid #e0e0e0;
    border-radius: 20px;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
    cursor: pointer;
    .payment__Contents {
        justify-content: space-between;
        margin-bottom: 10px;
        div {
            h6 {
                color: #50a433;
                font-weight: bold;
                max-width: 90%;
            }
            span {
                color: #707070;
                font-size: 13px;
            }
            &:first-child {
                max-width: 85%;
            }
            &:last-child {
                border-radius: 50%;
                width: 35px;
                height: 35px;
                border: 2px solid $primary-color;
                justify-content: center;
                padding: 2px;
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // background-color: $primary-color;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    color: #fff;
                }
            }
        }
    }
}

.empty-img {
    text-align: center;
    img {
        width: 100%;
        max-width: 600px;
        margin: auto;
        opacity: 0.5;
    }
    h6 {
        color: $primary-color;
        font-weight: bold;
        font-size: 20px;
    }
}
@include respond(laptop) {
    .invoice {
        ul {
            li {
                span {
                    &:last-child {
                        // font-family: $main-font-bold;
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}
@include respond(small-laptop) {
    .cart .form-box {
        padding: 30px 20px;
    }
    .horizontal-product p,
    .horizontal-product__contents__header {
        margin-bottom: 5px;
    }
    .horizontal-product__contents__header h6 {
        width: 85%;
    }
    .horizontal-product__contents__header button {
        height: 30px;
        width: 30px;
        max-width: 15%;
        i {
            font-size: 15px;
        }
    }
}

@include respond(tablet) {
    .page-label {
        height: 75px;
    }
    .steps {
        margin: 40px 0;
    }
}
@include respond(large-phone) {
    .page-title {
        padding-top: 20px;
    }
    .steps ul .step span {
        width: 25px;
        height: 25px;
    }
    .steps ul p {
        font-size: 11px;
    }
    .steps i {
        font-size: 12px;
    }
    .invoice .invoice-location span,
    .invoice .invoice-location p {
        font-size: 12px;
    }
}
