.forget-password {
    color: $primary-color;
    font-size: 12px;
    float: right;
    margin-top: 5px;
    transition: ease-in-out 0.3s;
    cursor: pointer;

    &:hover {
        color: $third-text-color;
        transition: ease-in-out 0.3s;
    }
}
.password-confirmation-margin-top {
    margin-top: 100px;
}
.password-confirmation-margin-0 {
    margin-top: 30px;
}
.auth-arabic {
    .forget-password-and-error-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .forget-password {
            float: left;
        }
    }
}
.auth {
    .submit-button {
        margin-top: 50px;
    }
    p {
        color: #cecece;
        font-size: 20px;

        a {
            color: $primary-color;
            font-weight: bold;
            &:hover {
                color: $third-text-color;
                transition: ease-in-out 0.3s;
            }
        }
    }
    .form-box .main-form-img {
        width: 425px;
    }
    // SignUp page Style
    .form-select {
        background-color: #f1f1f1 !important;
        border-radius: 10px;
        padding: 8px 15px;
        margin-bottom: 15px !important;
        font-size: 18px;
        color: #bcbcbc;
        color: $primary-color;

        option {
            //   background-color: $primary-color;
            transition: all 0.9 ease-in-out;
            padding: 20px;
            &:hover {
                background-color: #4ba716 !important;
            }
        }
    }
    .input-group {
        background-color: #f1f1f1;
        border: 1px solid #bcbcbc;
        height: 45px;
        border-radius: 10px;

        padding: 8px 15px;
        justify-content: space-between;
        .form-select {
            border: none;
            color: #bcbcbc;
            width: 20%;
        }
        input {
            // width: 77%;
            width: 72%;
            height: 100%;
            background-color: transparent;
            color: $secondary-color;

            &::placeholder {
                color: #bcbcbc;
                font-size: 18px;
            }
        }
        i {
            color: $primary-color;
            margin: auto 0px;
        }
        .password-input {
            width: 90%;
        }
    }
    // SignUp page Style End
}
@include respond(laptop) {
    .auth {
        .input-group {
            .form-select {
                width: 30%;
            }
            input {
                width: 67%;
                width: 65%;
            }
        }
    }
}
@include respond(small-laptop) {
    .auth {
        .input-group {
            .form-select {
                width: 25%;
            }
            input {
                width: 72%;
                width: 65%;
            }
        }
    }
}
@include respond(tablet) {
    .form-box {
        padding: 25px;
    }
    .auth .form-box .main-form-img {
        display: none;
    }
}
@include respond(phone) {
    .auth {
        .input-group {
            .form-select {
                width: 30%;
            }
            input {
                width: 70%;
                width: 67%;
            }
        }
    }
    .forget-password {
        font-size: 11px;
    }
}
