.order {
    padding: 10px;
    border: 2px solid #e0e0e0;
    border-radius: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
        border-color: $primary-color;
        transition: ease-in-out 0.3s;
    }
    .header-order {
        justify-content: space-between;
        margin-bottom: 10px;
        h5 {
            color: $primary-color;
            font-weight: bold;
        }
        span {
            color: #cecece;
            font-size: 14px;
        }
    }
    .body-order {
        column-gap: 15px;
        .books-pic {
            column-gap: 5px;
            padding: 0;
            margin: 0;
            align-items: flex-start;
            img {
                width: 50px;
                height: 65px;
            }
            span {
                display: block;
                width: 100%;
                height: 100%;
                background-color: #cecece;
                width: 50px;
                height: 65px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-weight: bold;
            }
        }
        .order-details {
            width: -webkit-fill-available;
            p {
                color: #9f9f9f;
                font-size: 16px;
                margin-bottom: 10px;
            }
            .order-price {
                justify-content: space-between;
                span {
                    &:first-child {
                        color: #9f9f9f;
                    }
                    &:last-child {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

@include respond(laptop) {
    .order .body-order .books-pic img {
        width: 45px;
        height: 60px;
    }
    .order .body-order .books-pic span {
        width: 45px;
        height: 60px;
    }
    .order .body-order .order-details p {
        font-size: 14px;
    }
}

@include respond(large-phone) {
    .order .header-order h5 {
        font-size: 14px;
    }
    .order .body-order .order-details p {
        font-size: 12px;
    }
    .order .body-order {
        flex-direction: column;
    }
    .order .body-order .order-details {
        margin-top: 19px;
    }
}
