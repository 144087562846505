.multi_container {
    .sidebar-main {
        background-color: $primary-color !important;
        color: #fff !important;
        height: 100% !important;
    }
    .slidebar {
        .sidebar-main {
            .first-back-btn,
            .second-back-btn {
                background-color: $third-text-color !important;
                color: #fff !important;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 10px;
                span {
                    color: #fff !important;
                    display: block;
                }
                svg {
                    filter: brightness(0) invert(1);
                    width: 12px;
                    height: 12px;
                }
            }
            .second-back-btn {
                background-color: $third-color !important;
                margin: 0px;
                border: none;
                span {
                    color: #fff !important;
                }
            }
        }
    }
    .sidebar-body {
        ul {
            li {
                padding: 8px 15px;
                transition: 0.3s all ease-in-out;
                border-bottom: solid 1px white;
                span {
                    span {
                        color: #fff !important;
                    }
                }
                &:hover {
                    background-color: green;
                    transition: 0.3s all ease-in-out;
                }
                svg {
                    filter: brightness(500);
                    width: 12px;
                    height: 12px;
                }
            }
            .section-seprator {
                margin: 10px !important;
                display: none !important;
            }
        }
    }
}
.section-seprator {
    display: none !important;
}
// Arabic Style

.multi_container-arabic {
    .sidebar-main {
        right: 0 !important;
        left: auto !important;
        transform: translateX(300px) !important;
    }
    .sidebar-main.show {
        transform: translateX(0px) !important;
        visibility: visible;
    }
    .sidebar-body ul li svg {
        transform: scale(-1);
    }
    .slidebar .sidebar-main .first-back-btn svg,
    .slidebar .sidebar-main .second-back-btn svg {
        transform: scale(-1);
    }
}
