.steps {
    color: #555464;
    margin-bottom: 30px;
    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        justify-content: center;
        li {
            &:last-child {
                margin-left: -20px;
            }
        }
        .step {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: max-content;
            position: relative;
            // margin-right: -54px;
            span {
                width: 35px;
                height: 35px;
                background-color: #e5e5e5;
                border-radius: 50%;
                z-index: 9;
                /* /////// */
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                cursor: pointer;
                margin-bottom: 5px;
            }
        }
        .step-hr {
            width: 30%;
            height: 5px;
            background-color: #e5e5e5;
            margin-bottom: 5px;
        }
        .stepActive {
            color: $primary-color;
            // font-weight: 700;
        }
        .bg-stepActive {
            background-color: $primary-color !important;
        }
        i {
            font-size: 18px;
        }
        p {
            font-size: 13px;
            margin-bottom: 0;
            position: absolute;
            bottom: -20px;
            white-space: nowrap;
        }
    }
}
