@import "../../../abstract/mixins";
.product-details-main {
    .product-details {
        margin-top: 100px;
    }
    .search-block-container {
        background-color: #f9f9f9;
        .search-block {
            width: 90%;
            margin: auto;
            padding: 15px 0;
            background-color: #f9f9f9;
            border-radius: 0px;
            column-gap: 15px;
            // margin-bottom: -85px;

            box-shadow: none;
            position: relative;
            z-index: 99;
            span {
                color: $secondary-color;
                font-weight: bold;
            }
            .input-group {
                background-color: #f1f1f1;
                border-radius: 10px;
                input {
                    border: 0;
                    background-color: transparent;
                }
                img {
                    width: 20px;
                }
                .input-group-text {
                    border: 0;
                    background-color: transparent;
                    padding: 10px 20px;
                    &:last-child {
                        padding: 10px 20px;
                        cursor: pointer;
                        background-color: $primary-color;
                        color: #fff;
                        border-radius: 10px;
                        border: 1px solid $primary-color;
                        transition: ease-in-out 0.3s;

                        &:hover {
                            border-color: $primary-color;
                            color: $primary-color;
                            background-color: #fff;
                            transition: ease-in-out 0.3s;
                        }
                    }
                }
            }
        }
    }
    .product-browser-detail {
        //
        .image-gallery-thumbnails-wrapper {
            padding-top: 20px;
        }
        .image-gallery-thumbnail {
            margin-left: 10px;
            // width: 90px;
            // height: 90px ;

            width: 65px;
            height: 65px;
        }

        .image-gallery-slide {
            img {
                width: 100%;
                height: 314px;
                object-fit: scale-down;
            }
        }
        .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
            direction: ltr !important;
            .image-gallery-thumbnail.active,
            .image-gallery-thumbnail:hover,
            .image-gallery-thumbnail:focus {
                border-color: $primary-color !important;
                border-width: 2px;
                border-radius: 20px;
                padding: 5px;
            }
        }
        .image-gallery-thumbnail {
            border: 1px solid silver;
            padding: 5px;
            border-radius: 20px;
        }
        .image-gallery-thumbnails-container {
            img {
                height: 100%;
                width: 100%;
                object-fit: scale-down;
                border-radius: 10px;
            }
        }
        //
        margin-top: 50px;
        .product-browser-detail__info {
            h3 {
                color: $primary-color;
                font-size: 26px;
                font-weight: bold;
            }
            h5 {
                margin-top: 30px;
            }
            p {
                margin-top: 30px;
                color: $secondary-color;
                font-size: 14px;

                .read-more {
                    display: block;
                    color: $primary-color;
                    cursor: pointer;
                    width: fit-content;
                }
            }
            .product-browser-detail__info__buttons {
                .price-quantity {
                    .qty {
                        border: solid 2px $primary-color;
                        padding: 5px 10px;
                        column-gap: 10px;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: bold;
                        border-radius: 20px;
                        color: black;
                        input {
                            width: 30px;
                            text-align: center;
                            color: black;
                            font-weight: bold;
                            font-size: 16px;
                        }
                        span {
                            color: $primary-color;
                            cursor: pointer;
                        }
                    }
                    .new-price {
                        color: $primary-color;
                        margin-left: 5px;
                        font-weight: bold;
                    }
                    .old-price {
                        color: #707070;
                        text-decoration: line-through;
                        font-size: 15px;
                        margin-left: 5px;
                    }
                    .price-quantity__price-span {
                        font-size: 20px;
                        font-weight: bold;
                        text-transform: capitalize;
                    }
                }
                .add-to-cart-wish-list {
                    margin-top: 20px;
                }
                .out_of_stock {
                    background-color: #bbbbbb;
                    border: none;
                    cursor: not-allowed;
                }
            }
        }
        .product-browser-detail__categories {
            .product-browser-detail__categories__titles {
                background-color: transparent;
                border-radius: 0;
                border: 0;
                display: flex;
                flex-direction: column;
                a {
                    display: block;
                    font-size: 15px;
                    color: #cecece;
                    padding: 0px 7px;
                    transition: ease-in-out 0.3s;
                    border-bottom: 0;
                    &:hover {
                        color: $third-text-color;
                        transition: ease-in-out 0.3s;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                    img {
                        width: 80px;
                        height: 80px;
                        object-fit: scale-down;
                    }
                }
            }
            .product-browser-detail__categories_image {
                margin-top: 15px;
                img {
                    width: 100%;
                }
            }
        }
    }
}
// Arabic Style Start
.product-details-main-arabic {
    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: -1px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .product-browser-detail .product-browser-detail__info .product-browser-detail__info__buttons .price-quantity .new-price {
        margin-left: 0;
        margin-right: 5px;
    }
}
// Arabic Style End
@include respond(laptop) {
    .product-details-main .product-browser-detail .product-browser-detail__categories .product-browser-detail__categories__titles a {
        font-size: 17px;
    }

    .product-details-main .product-browser-detail .product-browser-detail__info h3 {
        font-size: 22px;
    }
    .product-details-main .product-browser-detail .product-browser-detail__info p {
        font-size: 13px;
    }
    .product-details-main .product-browser-detail .image-gallery-thumbnails-wrapper {
        padding-top: 10px;
    }
}
@include respond(small-laptop) {
    .product-browser-detail .image-gallery {
        margin-bottom: 30px;
    }
    .product-details-main .product-browser-detail .image-gallery-slide img {
        height: 270px;
    }

    .product-details-main .product-browser-detail .image-gallery-thumbnail {
        width: 45px;
        height: 45px;
    }
    .product-details-main .product-browser-detail .product-browser-detail__categories .product-browser-detail__categories__titles a {
        text-align: right;
    }
    // Arabic Style Start
    .product-details-main-arabic {
        .product-browser-detail .product-browser-detail__categories .product-browser-detail__categories__titles a {
            text-align: left;
        }
    }
    // Arabic Style End
}

@include respond(large-phone) {
    .product-details-main .product-browser-detail .product-browser-detail__info h5 {
        margin-top: 15px;
    }
    .product-details-main .product-browser-detail .product-browser-detail__info h3 {
        font-size: 18px;
    }
    .product-details-main .product-browser-detail .product-browser-detail__info p {
        margin-top: 15px;
    }
    .product-browser-detail__info {
        margin-bottom: 20px;
    }
    .product-details-main .product-browser-detail .image-gallery-slide img {
        height: 225px;
    }

    .product-details-main .product-browser-detail .image-gallery-thumbnail {
        width: 35px;
        height: 35px;
    }
}
