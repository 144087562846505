.reusable-label {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: space-between;

    .requiredField {
        color: $third-text-color;
    }

    span {
        font-weight: bold;
        letter-spacing: 1px;
        margin-bottom: 5px;
        display: block;
        color: $secondary-color;

        // color: $cusLightBlack;
    }

    img {
        margin: 0 10px;
    }
    .addNew_label {
        padding: 3px 6px;
        // width: 70px;
        color: white;
        background-color: #259e25;
        text-align: center;
        border-radius: 20px;
        font-weight: 100;
        font-size: 13px;
    }

    .check-isChild {
        display: flex;
        align-items: center;
        column-gap: 5px;
        input {
            width: 15px;
            cursor: pointer;
        }
    }
}
