.landing-page {
    // padding-top: 100px;
    // padding-bottom: 50px;
    min-height: 400px;
    position: relative;
    background-color: #fff;
    .search-container {
        margin-top: -36px;
        .search-block {
            width: 60%;
            margin: auto;
            padding: 15px;
            background-color: #fff;
            border-radius: 20px;
            column-gap: 15px;
            margin-bottom: -85px;
            box-shadow: 0 0 5px 0px silver;
            position: relative;
            z-index: 99;
            span {
                color: #c2c2c2;
                // font-weight: bold;
            }
            .input-group {
                background-color: #f1f1f1;
                border-radius: 10px;
                input {
                    border: 0;
                    background-color: transparent;
                }
                img {
                    width: 20px;
                }
                .input-group-text {
                    border: 0;
                    background-color: transparent;
                    padding: 10px 20px;
                    &:last-child {
                        padding: 10px 20px;
                        cursor: pointer;
                        background-color: #0a74ba;
                        color: #fff;
                        border-radius: 10px;
                        border: 1px solid #0a74ba;
                        transition: ease-in-out 0.3s;

                        &:hover {
                            background-color: #fff;
                            transition: ease-in-out 0.3s;
                        }
                    }
                }
            }
        }
    }
    .img-rectangle {
        position: absolute;
        left: 0%;
        max-width: 40%;
    }
    .img-Vase {
        position: absolute;
        right: 0;
        bottom: -100px;
        width: 335px;
    }
    .landing-page__main-img {
        img {
            width: 100%;
            position: relative;
            max-height: 673px;
            height: 100%;
            object-fit: contain;
        }
    }
    .landing-contents {
        padding-left: 50px;
        h2,
        h3 {
            // font-family: $main-font-bold;
            -webkit-text-stroke: thin;
            font-size: 2.8rem;
            margin: 0;
        }
        h2 {
            line-height: 1;
            color: $primary-color;
        }
        h3 {
            color: #c2c2c2;
        }
        hr {
            width: 50px;
            height: 3px;
            margin: 2rem 0;
        }
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
        button {
            border: 2px solid $primary-color;
            color: $primary-color;
            background-color: transparent;
            padding: 10px;
            width: 200px;
            transition: ease-in-out 0.3s;
            text-align: center;
            border-radius: 30px;
            margin-top: 50px;
            &:hover {
                color: #fff;
                background-color: $primary-color;
                transition: ease-in-out 0.3s;
            }
        }
    }
}
.landing-page-No-Carousel-Data {
    // background: rgb(110, 177, 35);
    // background: linear-gradient(270deg, rgba(48, 149, 69, 1) 1%, rgba(110, 177, 35, 1) 100%);
    padding-top: 85px;
    padding-bottom: 38px;
}

.category-push-menu {
    display: block;
    color: $primary-color;
    h2 {
        padding: 20px 0;
    }
    ul {
        padding: 0;
        li {
            div {
                padding-bottom: 10px;
            }
        }
    }
    .sideMenu_List {
        margin-left: 10px;
    }
    .bm-menu-wrap {
        width: 350px !important;
    }
    .bm-menu-wrap,
    .bm-overlay {
        position: absolute;
        top: 0;
        left: 0;
    }
    i {
        font-size: 20px;
    }
    button {
        background-color: white !important;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $primary-color;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
// Arabic Style Start
.landing-page-arabic {
    .landing-contents {
        position: relative;
        padding-left: 0px;
        padding-right: 50px;
    }

    .search-block {
    }
    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: -1px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .img-rectangle {
        position: absolute;
        left: auto;
        right: 0%;
        max-width: 40%;
        transform: scale(-1);
    }
}

@media (max-width: 934px) {
    .landing-page .search-container {
        margin-top: 16px;
    }
}
@include respond(laptop) {
    .landing-page .landing-contents h2,
    .landing-page .landing-contents h3 {
        font-size: 2.5rem;
    }

    .landing-page .img-Vase {
        width: 285px;
    }
    .landing-page .landing-contents hr {
        margin: 1.5rem 0;
    }

    .landing-page .landing-contents button,
    .landing-page .carousel-caption button {
        width: 180px;
    }
    .category-push-menu {
        .bm-menu-wrap {
            width: 250px !important;
        }
    }
    // .landing-page .carousel-item img {
    //     height: 600px;
    // }
}
@include respond(small-laptop) {
    .landing-page .landing-contents h2,
    .landing-page .landing-contents h3 {
        font-size: 2rem;
    }
    .landing-page .img-Vase {
        width: 240px;
    }
    .landing-page .landing-contents button,
    .landing-page .carousel-caption button {
        width: 170px;
        position: relative;
        z-index: 99;
        margin-top: 10px;
    }
    .search-block {
        width: 70%;
        padding: 10px;
        column-gap: 10px;
        margin-bottom: -85px;
    }
    .search-block .input-group .input-group-text:last-child {
        padding: 7px 15px;
    }
    .search-block .input-group .input-group-text {
        padding: 7px 10px;
    }
    .bm-burger-button {
        height: 20px;
    }
    .search-block .input-group img {
        width: 18px;
    }
    .landing-page .img-Vase {
        display: none;
    }
    .landing-page-No-Carousel-Data {
        padding-top: 40px;
        padding-bottom: 28px;
    }
}

@include respond(tablet) {
    .landing-page .img-rectangle {
        max-width: 100%;
        max-height: 510px;
    }
    .landing-page .landing-contents button,
    .landing-page .carousel-caption button {
        margin-bottom: 30px;
    }
    .search-block {
        width: 100%;
    }
    // .landing-page .carousel-item img {
    //     height: 500px;
    // }
}
@include respond(large-phone) {
    .category-push-menu {
        .bm-menu-wrap {
            width: 200px !important;
        }
    }
    // .landing-page .carousel-item img {
    //     height: 300px;
    // }
    .landing-page .landing-contents button,
    .landing-page .carousel-caption button {
        width: 120px;
        padding: 7px;
    }
    .landing-page .carousel-caption p {
        -webkit-line-clamp: 1;
    }
    .landing-page .search-container .search-block {
        width: 90%;
    }
}
