.footer {
    background-color: #23323f;
    padding: 40px 0;
    h3 {
        width: 200px;
        margin-bottom: 30px;
        padding-right: 5px;
        text-transform: capitalize;
        font-size: 21px;
        color: #fff;
    }
}

.footer__lift-side {
    h3 {
        width: auto;
        word-wrap: break-word;
        color: #fff;
    }
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 15px;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 10px;
            cursor: pointer;
            img {
                max-width: 55px;
                height: 25px;
            }
        }
    }
}
.footer__mid {
    position: relative;

    ul {
        padding: 0;
        li {
            margin-bottom: 15px;
            a {
                text-decoration: none;
                color: $secondary-color;
                text-transform: capitalize;
                color: #848484;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            color: #fff;
            transition: 0.3s;
        }
            }
        }
    }
    p {
        margin-bottom: 0;
        color: #848484;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            color: #fff;
            transition: 0.3s;
        }
    }
    span {
        color: $secondary-color;
        font-size: 14px;
        line-height: 1.2;
        display: block;
        direction: initial;
        
    }
}
.copy-write {
    background-color: #e2e2e2;
    padding: 10px 0;
    color: #848484;

    .flex {
        justify-content: space-between;
    }
    p {
        margin: 0;
        text-transform: capitalize;
    }
    ul {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        column-gap: 25px;
        a {
            color: #848484;
            &:hover {
                i {
                    color: $primary-color;
                    transition: ease-in-out 0.3s;
                }
            }
        }
    }
}

.footer__branch-data-modal {
    h5 {
        color: $primary-color !important;
    }
    i {
        cursor: pointer;
    }
    .modal-content {
        border-radius: 20px;
        // margin-top: 50%;
        background-color: #fff;
        // background-color: $third-text-color;
    }
    ul {
        background-color: #fff;
        padding: 15px;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }
}
// Arabic Style Start

// Arabic Style End
@include respond(tablet) {
    .footer__mid,
    .footer__lift-side {
        margin-bottom: 20px;
    }
    .footer h3 {
        margin-bottom: 10px;
    }
    .footer__mid {
        p {
            font-size: 14px;
        }
    }
    .footer__mid ul li {
        margin-bottom: 10px;
    }
    .footer__lift-side ul li img {
        max-width: 45px;
    }
}
@include respond(large-phone) {
    .copy-write ul {
        column-gap: 10px;
    }
    .copy-write p {
        font-size: 10px;
    }
}
