@import "../abstract/mixins";
:root {
    font-size: 16px;

    @include respond(custom-media, 0, 576px) {
        font-size: 12px;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: $main-font;
    font-size: $default-font-size !important;
    // overflow: hidden auto;
    // max-width: 100vw;
    min-height: 100vh;
    // user-select: none; 
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
        background: darken($primary-color, 10%);
        border-radius: 20px;
    }
}

ul {
    list-style: none;
    padding: 0;
}

a {
    text-decoration: none !important;
    color: inherit;

    &:hover,
    &:active,
    &:focus {
        color: inherit;
    }
}

button,
input,
textarea {
    font-size: $default-font-size;
    border: none;
}

input,
select,
textarea {
    font-family: inherit;
    width: 100%;
}
.no-padd {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.margin-auto {
    margin: auto !important;
}
button:not(:disabled),
select,
input[type="submit"],
input[type="date"],
input[type="color"] {
    cursor: pointer;
}

input,
textarea {
    color: inherit;

    &::-webkit-input-placeholder {
        font: 500 smaller sans-serif;
        text-transform: capitalize;
        color: inherit;
        transition: opacity 0.3s;
    }

    &:focus::-webkit-input-placeholder {
        opacity: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        color: $primary-color !important;
        box-shadow: 0 0 0 5rem white inset !important;
    }
}

input {
    &[type="number"] {
        appearance: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            margin: 0;
            appearance: none;
        }
    }

    &[type="color"] {
        appearance: none;

        &::-webkit-color-swatch {
            border: none;
        }
    }
}

textarea {
    resize: none;
}

button:disabled {
    cursor: auto;
}

button:focus,
:focus {
    outline: none !important;
    box-shadow: none !important;
}

.clickable {
    cursor: pointer;
}
.flex {
    display: flex;
    align-items: center;
}
.primary-color {
    color: $primary-color;
}

.validation-message--underline {
    font-size: 12px;
    margin-top: -15px;
    color: rgb(238, 20, 20);
}

.swal-overlay {
    font-family: "Segoe UI", sans-serif;

    .swal-button--confirm {
        background-color: $primary-color;

        &:hover {
            background-color: darken($primary-color, 4%);
        }
    }
}

.select__value-container--has-value {
    .select__single-value {
        font-weight: bold;
        color: $primary-color !important;
    }
}

@for $i from 1 through 3 {
    .ls-#{$i} {
        letter-spacing: #{$i}px;
    }
}

.btn--primary {
    font-weight: 500;
    display: block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
    padding: 11px 0;
    color: white;
    background-color: $primary-color;
    border: 0;

    &:disabled {
        background-color: $secondary-color;
    }
}

.submit-button {
    display: block;
    margin-bottom: 20px;
    background-color: #0a74ba;
    color: #fff;
    min-width: 180px;
    border-radius: 20px;
    padding: 8px;
    transition: ease-in-out 0.4s;
    border: 1px solid #0a74ba;
    &:hover {
        background-color: #fff;
        color: #0a74ba;
        transition: ease-in-out 0.4s;
    }
}
.loader-modal {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    to {
        -webkit-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        -webkit-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    to {
        -ms-transform: rotateY(360deg);
        -moz-transform: rotateY(360deg);
        -webkit-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.main-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;

    .lds-default {
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-default div {
        position: absolute;
        width: 6px;
        height: 6px;
        background: $primary-color;
        border-radius: 50%;
        animation: lds-default 1.2s linear infinite;
    }

    .lds-default div:nth-child(1) {
        top: 37px;
        left: 66px;
        animation-delay: 0s;
    }

    .lds-default div:nth-child(2) {
        top: 22px;
        left: 62px;
        animation-delay: -0.1s;
    }

    .lds-default div:nth-child(3) {
        top: 11px;
        left: 52px;
        animation-delay: -0.2s;
    }

    .lds-default div:nth-child(4) {
        top: 7px;
        left: 37px;
        animation-delay: -0.3s;
    }

    .lds-default div:nth-child(5) {
        top: 11px;
        left: 22px;
        animation-delay: -0.4s;
    }

    .lds-default div:nth-child(6) {
        top: 22px;
        left: 11px;
        animation-delay: -0.5s;
    }

    .lds-default div:nth-child(7) {
        top: 37px;
        left: 7px;
        animation-delay: -0.6s;
    }

    .lds-default div:nth-child(8) {
        top: 52px;
        left: 11px;
        animation-delay: -0.7s;
    }

    .lds-default div:nth-child(9) {
        top: 62px;
        left: 22px;
        animation-delay: -0.8s;
    }

    .lds-default div:nth-child(10) {
        top: 66px;
        left: 37px;
        animation-delay: -0.9s;
    }

    .lds-default div:nth-child(11) {
        top: 62px;
        left: 52px;
        animation-delay: -1s;
    }

    .lds-default div:nth-child(12) {
        top: 52px;
        left: 62px;
        animation-delay: -1.1s;
    }

    @keyframes lds-default {
        0%,
        20%,
        80%,
        100% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.5);
        }
    }
}

.section-title {
    text-align: center;
    h3 {
        color: #6c9dbc !important;
        padding: 17px 11px;
        border-radius: 19px;
        text-transform: uppercase;
    }
}
.dateBlock .react-datepicker-wrapper {
    width: 100%;
}

.text-input-block .input-group,
.dateBlock .react-datepicker__input-container input {
    width: 100%;
    margin-bottom: 0 !important;
    padding: 5px 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: $primary-color !important;
    font-weight: bold;
}

.text-input-block .validate_Message {
    font-size: 12px;
    display: block;
    height: 0;
    margin: 3px 5px 0 5px;
    color: $primary-color;
}

.reusable-label {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: space-between;

    .requiredField {
        color: $primary-color;
    }

    span {
        font-weight: bold;
        letter-spacing: 1px;
        color: $primary-color;
    }

    img {
        margin: 0 10px;
    }
}
.react-multi-carousel-list {
    direction: ltr;
}
@include respond(small-laptop) {
    .submit-button {
        font-size: 14px;
    }

    .column-reverse-under992 {
        flex-direction: column-reverse;
    }
}

@include respond(tablet) {
    .column_reverse_under768 {
        flex-direction: column-reverse;
    }
}
