.product {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 25px;
    background-color: #fff;
    border: 1px solid #b2b2b2;
    // width: 287px !important;
    border-radius: 50px;
}

.product__header {
    padding: 10px 56px;
    position: relative;
    text-align: center;
    overflow: hidden;
    border-bottom: 1px solid #b2b2b2;
    height: 261px;
    .product__header__book-img {
        width: 100%;
        border-radius: 25px;
        cursor: pointer;
        height: 100%;
        // object-fit: scale-down;
    }
    i {
        color: #ff485a;
    }
    span,
    a {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 7px;
        border-radius: 10px;
        background-color: #fff;
        justify-content: center;
        cursor: pointer;
        transition: ease-in-out 0.3s;
        box-shadow: 0 0 3px 0 silver;

        &:hover {
            // background-color: #ff485a;
            // transition: ease-in-out 0.3s;

            img {
                filter: brightness(5);
                transition: ease-in-out 0.3s;
            }
        }
        img {
            width: 20px;
            transition: ease-in-out 0.3s;
        }
    }
}

.slick-slide {
    padding: 7px;
}

.product__contents {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
        font-size: 1rem;
        height: 37px;
        cursor: pointer;
        color: #0a74ba;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    p {
        font-size: 12px;
        color: #9f9f9f;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 36px;
        // font-family: "OpenSans";
    }
}
.product--buttons {
    justify-content: space-between;
    div {
        column-gap: 10px;
        span {
            color: $third-text-color;
            font-size: 17px;
            font-weight: bold;
        }
        .oldPrice {
            text-decoration: line-through;
            color: #bfbfbf;
            font-weight: 100;
        }
    }
    .add-to-cart {
        padding: 10px 10px;
        width: 59px;
        height: 29px;
        column-gap: 10px;
        justify-content: center;
        font-size: 14px;
        border-radius: 20px;
        transition: ease-in-out 0.3s;
        color: #fff;
        background-color: #0a74ba;
        border: 1px solid #0a74ba;

        img {
            transition: ease-in-out 0.3s;
            height: 18px;
            width: 18px;
        }
    }

    .out_of_stock {
        background-color: #bbbbbb;
        border: none;
    }
    .added-to-cart {
        background-color: $primary-color;
        transition: ease-in-out 0.3s;
        img {
            filter: brightness(5);
        }
    }
}
//square-product style

.square-product {
    border-radius: 0;
    border: none;
    background-color: #f0f4f7;
    .product__header {
        padding: 0;
        border-bottom: 0;
        height: 381px;
        .product__header__book-img {
            margin: 0;
            border-radius: 0;
            height: 100%;
            object-fit: fill;
        }
    }
}

// Arabic Style Start
.product-arabic {
}
// Arabic Style End
@include respond(laptop) {
    .product__header .product__header__book-img {
        width: 100%;
        margin-bottom: -100px;
    }
}
@include respond(small-laptop) {
    .product__header .product__header__book-img {
        height: 250px;
    }
    .product__header {
        padding: 10px 30px;
    }
}

@include respond(small-laptop) {
    .product__contents {
        min-height: auto;
    }
}
