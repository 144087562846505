@import "../../../abstract/mixins";
.about-page {
    overflow: hidden;
    .about-page__about-passages-container {
        position: relative;
        border-radius: 40px 20px;
        padding: 30px;
        margin-top: 80px;
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            position: relative;
        }
        .image-display-none {
            display: none;
        }
        .about-page__first-article {
            width: 1000px;
        }
        h4 {
            color: $primary-color;
            font-size: 25px;
            // font-family: $main-font-bold;
        }
        div {
            // max-width: 568px;
            p {
                width: 100%;
                text-align: justify;
                color: #000 !important;
                padding: 0;
                p {
                    color: #000 !important;
                }
            }
        }
    }
    .about-page__about-passages-container-no-image {
        p {
            width: 100%;
        }
    }

    .page-title {
        padding-left: 30px;
    }
}
// Arabic Style Start
.about-page-arabic {
    .about-page__about-passages-container {
        p {
            word-break: break-word;
            padding: 0 0 0 120px;
        }
    }

    .page-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
    }

    // .about-page__about-passages-container img {
    //     position: absolute;
    //     right: auto;
    //     left: -91px;
    //     transform: scaleX(-1);
    // }
}
// Arabic Style End
@include respond(smallDesktop) {
    .about-page {
        .about-page__about-passages-container {
            .about-page__first-article {
                width: 850px;
            }
        }
    }
    // Arabic Style Start
    // .about-page-arabic {
    //     .about-page__about-passages-container img {
    //         right: auto;
    //         left: -108px;
    //     }
    // }
    // Arabic Style End
}
@include respond(laptop) {
    .about-page {
        .about-page__about-passages-container {
            .about-page__first-article {
                width: 750px;
            }
            img {
            }
        }
    }
    // Arabic Style Start
    // .about-page-arabic {
    //     .about-page__about-passages-container img {

    //     }
    // }
    // Arabic Style End
}

@media (max-width: 769px) {
    .about-page {
        .about-page__about-passages-container {
            .about-page__first-article {
                width: 100%;
            }
        }
    }
}

@include respond(small-laptop) {
    .about-page {
        .about-page__about-passages-container {
            .about-page__first-article {
                width: 500px;
            }
            h4 {
                font-size: 18px;
            }
        }
        .page-label {
            width: 56%;
        }
        .page-title {
            font-size: 40px;
        }
    }
    // Arabic Style Start
    .about-page-arabic {
    }
    // Arabic Style End
}
@include respond(tablet) {
    .about-page {
        .about-page__about-passages-container {
            .about-page__first-article {
                width: 450px;
            }
        }
        .page-title {
            font-size: 30px;
        }
    }
    .about-page .about-page__about-passages-container p {
        width: 88%;
    }
    .about-page .about-page__about-passages-container-no-image {
        p {
            width: 100%;
        }
    }
    // Arabic Style Start
    .about-page-arabic {
        .about-page__about-passages-container {
            p {
                word-break: normal;
                padding: 0 0 0 110px;
            }
        }
        // .about-page__about-passages-container img {
        //     right: auto;
        //     left: -100px;
        // }
    }
    // Arabic Style End
}
@include respond(large-phone) {
    .about-page {
        .about-page__about-passages-container {
            .about-page__first-article {
                width: auto;
            }
            p {
                word-break: break-word;
                padding: 0;
            }
        }
        .page-label {
            width: 61%;
        }
        .page-title {
            font-size: 25px;
        }
    }
    .about-page .about-page__about-passages-container p {
        width: 100%;
    }
}
@include respond(phone) {
    .about-page {
        .page-title {
            font-size: 16px;
        }
    }
}
