.bg-gray-container {
    background-color: #f0f4f7;
}

.new-title {
    margin-top: 70px;
    margin-bottom: 100px;
    overflow: hidden;
    padding-bottom: 20px !important;
    .carousel-item-padding-40-px {
        padding: 10px;
    }

    .react-multi-carousel-list {
        padding-bottom: 50px;
    }
    .rec {
        .jsGVec,
        .dsyKYi {
            background-color: #4fa4333f;
        }
        .jsGVec:hover:enabled,
        .dsyKYi:hover:enabled,
        .dsyKYi:focus:enabled .jsGVec:focus:enabled {
            color: #fff;
            background-color: rgb(80, 164, 51);
            box-shadow: 0 0 2px 0 #333;
        }
    }
    .rtl {
        direction: rtl !important;
    }
    .ltr {
        direction: ltr !important;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(4% + 42%) !important;
        // top: 430px !important;
        bottom: 0 !important;
    }

    .react-multiple-carousel__arrow--right {
        right: calc(4% + 42%) !important;
        // top: 430px !important;
        bottom: 0 !important;
    }
}

.react-multiple-carousel__arrow--left {
    left: calc(4% + 40%) !important;
    // top: 430px !important;
    bottom: 0 !important;
    height: 10px;
}

.react-multiple-carousel__arrow--right {
    right: calc(4% + 40%) !important;
    // top: 430px !important;
    bottom: 0 !important;
    height: 10px;
}
.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
    background-color: rgb(80, 164, 51) !important;
}
@include respond(tablet) {
    .new-title {
        margin-bottom: 40px;
    }

    // .react-multiple-carousel__arrow--left {
    //     left: calc(4% + 25%) !important;
    // }

    // .react-multiple-carousel__arrow--right {
    //     right: calc(4% + 25%) !important;
    // }
}

@include respond(tablet) {
    .new-title {
        .react-multiple-carousel__arrow--left {
            left: calc(4% + 35%) !important;
        }

        .react-multiple-carousel__arrow--right {
            right: calc(4% + 35%) !important;
        }
    }
    .react-multiple-carousel__arrow--left {
        left: calc(4% + 35%) !important;
    }

    .react-multiple-carousel__arrow--right {
        right: calc(4% + 35%) !important;
    }
}

@include respond(phone) {
    .new-title {
        .react-multiple-carousel__arrow--left {
            left: calc(4% + 32%) !important;
        }

        .react-multiple-carousel__arrow--right {
            right: calc(4% + 32%) !important;
        }
    }
    .react-multiple-carousel__arrow--left {
        left: calc(4% + 32%) !important;
    }

    .react-multiple-carousel__arrow--right {
        right: calc(4% + 32%) !important;
    }
}
